import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Header, Label } from "components/core";
import { GREY } from "config/colors";
import { changeLanguage } from "config/i18n";
import imgError from "assets/images/error.svg";
import * as S from "./styles";

const Error = () => {
  const { t: translate } = useTranslation();

  useEffect(() => {
    changeLanguage(navigator.language);
  }, []);

  return (
    <S.Container>
      <Helmet>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          name="viewport"
        />
      </Helmet>
      <Header />
      <S.Content>
        <S.Image src={imgError} />
        <Label fontWeight="bold" fontSize={20} marginTop={54}>
          Ops!
        </Label>
        <Label color={GREY} marginTop={16}>
          {translate("error-message")}
        </Label>
      </S.Content>
    </S.Container>
  );
};

export default Error;
