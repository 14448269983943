import React from "react";
import ErrorPresentational from "components/presentations/Error";
import { GlobalStyle } from "components/core";

const Error = () => (
  <>
    <GlobalStyle />
    <ErrorPresentational />
  </>
);

export default Error;
